import services from "../axios/dbManag";
import store from "../store/index";

const token = store.getters.tokenAdb2c
  ? store.getters.tokenAdb2c
  : localStorage.getItem("tokenAdb2c");

export const deleteEsportazione = (guidFile) => {
  return services.dbManag
    .post("/esportazioni/del", {
      guidFile,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getList = (apiLink, params) => {
  return services.dbManag
    .post(apiLink, params)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const downloadEsportazione = (guidFile) => {
  const url = `${services.baseUrl}/esportazioni/download?guidFile=${guidFile}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `${guidFile}.csv`);
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

export const esportaTesserati = (
  id_societa,
  stagione,
  nome,
  cognome,
  certificato,
  id_tipo_tessera,
  id_categoria,
  id_qualifica,
  id_disciplina,
  id_categoria_Eta,
  cod_tessera,
  data_emissione_da,
  data_emissione_a,
  id_comitato,
  id_regione,
  id_provincia,
  id_comune,
  tessere_estive,
  tessere_sat,
  da_approvare,
  //verificato,
  stampate,
  stampate_dal,
  stampate_al,
  note_richiesta
) => {
  return services.dbManagV2
    .post("/esportazione/tesserati", {
      id_societa,
      stagione,
      nome,
      cognome,
      certificato,
      id_tipo_tessera,
      id_categoria,
      id_qualifica,
      id_disciplina,
      id_categoria_Eta,
      cod_tessera,
      data_emissione_da,
      data_emissione_a,
      id_comitato,
      id_regione,
      id_provincia,
      id_comune,
      tessere_estive,
      tessere_sat,
      da_approvare,
      stampate,
      stampate_dal,
      stampate_al,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaTesseratiGold = (stagione, note_richiesta) => {
  return services.dbManag
    .post("/esportazione/tessere-premium", {
      stagione,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaScuole = (
  anno,
  id_stato,
  id_disciplina,
  id_tipologia,
  id_societa,
  id_comitato,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/scuole/richieste", {
      anno,
      id_stato,
      id_disciplina,
      id_tipologia,
      id_societa,
      id_comitato,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaCampionatiPromo = (anno, note_richiesta) => {
  return services.dbManag
    .post("/esportazione/campionati/promo", {
      anno,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaCampionatiUnder = (anno, note_richiesta) => {
  return services.dbManag
    .post("/esportazione/campionati/giovanili", {
      anno,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaAlbo = (
  id_tipo_albo,
  id_categoria,
  id_qualifica,
  codice_snm,
  data_acq_from,
  data_acq_to,
  data_dec_from,
  data_dec_to,
  data_fine_from,
  data_fine_to,
  nome,
  cognome,
  id_societa,
  verifica,
  id_stato,
  id_stato_utenza,
  in_regola_tesseramento,
  in_regola_aggiornamento,
  ultimo_pagamento_albo_da,
  ultimo_pagamento_albo_a,
  pagamenti_non_registrati,
  id_cr,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/albo", {
      id_tipo_albo,
      id_categoria,
      id_qualifica,
      codice_snm,
      data_acq_from,
      data_acq_to,
      data_dec_from,
      data_dec_to,
      data_fine_from,
      data_fine_to,
      nome,
      cognome,
      id_societa,
      verifica,
      id_stato,
      id_stato_utenza,
      in_regola_tesseramento,
      in_regola_aggiornamento,
      ultimo_pagamento_albo_da,
      ultimo_pagamento_albo_a,
      pagamenti_non_registrati,
      id_cr,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaSocieta = (
  denominazione,
  cod_affiliazione,
  comitato,
  stagione,
  stagione_corrente,
  disciplina,
  affiliazione_dal,
  affiliazione_al,
  regione,
  provincia,
  comune,
  stato,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/societa", {
      denominazione,
      cod_affiliazione,
      comitato,
      stagione,
      stagione_corrente,
      disciplina,
      affiliazione_dal,
      affiliazione_al,
      regione,
      provincia,
      comune,
      stato,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaFederali = (
  stagione,
  nome,
  cognome,
  numero_albo,
  id_qualifica,
  id_comitato_regionale,
  data_emissione_da,
  data_emissione_a,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/tesserati-federali", {
      stagione,
      nome,
      cognome,
      numero_albo,
      id_qualifica,
      id_comitato_regionale,
      data_emissione_da,
      data_emissione_a,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaImpianti = (
  id_provincia,
  id_comune,
  denominazione,
  stato_omologazione,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/impianti", {
      id_provincia,
      id_comune,
      denominazione,
      stato_omologazione,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaCorsi = (
  stagione,
  id_tipo_corso,
  id_sottotipo_corso,
  search,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/corsi", {
      stagione,
      id_tipo_corso,
      id_sottotipo_corso,
      search,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const esportaCorsiAggiornamentoDirigenti = (
  stagione,
  search,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/corsi/aggiornamento/dirigenti", {
      stagione,
      search,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const esportaCorsiAggiornamentoNonDirigenti = (
  stagione,
  search,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/corsi/aggiornamento/non-dirigenti", {
      stagione,
      search,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const esportaCorsiFormazioneDirigenti = (
  stagione,
  search,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/corsi/formazione/dirigenti", {
      stagione,
      search,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const esportaCorsiConPartecipanti = (
  stagione,
  id_tipo_corso,
  id_sottotipo_corso,
  search,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/corsi/con-partecipanti", {
      stagione,
      id_tipo_corso,
      id_sottotipo_corso,
      search,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaIscritti = (
  id_corso,
  id_Persona,
  idoneo,
  scontato,
  approvato,
  pagato,
  presente,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/corsi/partecipanti", {
      id_corso,
      id_Persona,
      idoneo,
      scontato,
      approvato,
      pagato,
      presente,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaMovimentiSocieta = (
  id_societa,
  stagione,
  tipo_mov,
  causale_mov,
  descrizione,
  approvato,
  data_da,
  data_a,
  data_appr_da,
  data_appr_a,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/societa/movimenti", {
      id_societa,
      stagione,
      tipo_mov,
      causale_mov,
      descrizione,
      approvato,
      data_da,
      data_a,
      data_appr_da,
      data_appr_a,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaAffiliazioniEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  approvato,
  id_societa,
  mese,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/affiliazioni", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      approvato,
      id_societa,
      mese,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaTesseramentiEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  approvato,
  id_societa,
  numero,
  mese,
  causale,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/tesseramenti", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      approvato,
      id_societa,
      numero,
      mese,
      causale,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaPagamentiEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  tipo_pagamento,
  tipoCredDeb,
  approvato,
  id_societa,
  id_persona,
  numero,
  mese,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/pagamenti", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      tipo_pagamento,
      tipoCredDeb,
      approvato,
      id_societa,
      id_persona,
      numero,
      mese,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaMovimentiEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  id_mov_famiglia,
  id_mov_manuale,
  tipoCredDeb,
  id_societa,
  id_persona,
  numero,
  mese,
  causale,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/movimenti", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      id_mov_famiglia,
      id_mov_manuale,
      tipoCredDeb,
      id_societa,
      id_persona,
      numero,
      mese,
      causale,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaSanzioniEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  id_societa,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/sanzioni", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      id_societa,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaTessereSpecialiEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  id_persona,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/tessere-speciali", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      id_persona,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaTessereGoldEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  id_persona,
  id_societa,
  pagato_tramite,
  approvato,
  mese,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/movimenti/tessera/gold", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      id_persona,
      id_societa,
      pagato_tramite,
      approvato,
      mese,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaSaldoEconomato = (
  stagione,
  id_comitato,
  id_societa,
  data_appr_da,
  data_appr_a,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/saldi", {
      stagione,
      id_comitato,
      id_societa,
      data_appr_da,
      data_appr_a,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaCompensazioniEconomato = (
  stagione,
  id_comitato,
  data_ins_da,
  data_ins_a,
  data_appr_da,
  data_appr_a,
  approvato,
  id_societa,
  numero,
  mese,
  causale,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report/compensazioni", {
      stagione,
      id_comitato,
      data_ins_da,
      data_ins_a,
      data_appr_da,
      data_appr_a,
      approvato,
      id_societa,
      numero,
      mese,
      causale,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaSintesiMensileEconomatoDettaglio = (
  id_comitato,
  stagione,
  mese,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/sintesi/pagamenti/mensili/dettagli", {
      id_comitato,
      stagione,
      mese,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaSintesiMensileEconomato = (
  id_comitato,
  stagione,
  mese,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/sintesi/pagamenti/mensili", {
      id_comitato,
      stagione,
      mese,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaReport = (
  stagione,
  compara,
  ids_comitati,
  ids_livelli,
  data_da,
  data_a,
  note_richiesta
) => {
  return services.dbManag
    .post("/esportazione/economato/report", {
      stagione,
      compara,
      ids_comitati,
      ids_livelli,
      data_da,
      data_a,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const esportaStorico = (id_persona, note_richiesta) => {
  return services.dbManag
    .post("/esportazione/persone/storico", {
      id_persona,
      note_richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
